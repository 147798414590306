<header [ngClass]="{ active: changeColor }" class="show-header">
  <div class="container-header">
    <div class="nav">
      <div class="nav-left">
        <div class="left-img">
          <img
            class="pre-scroll-img"
            routerLink="/"
            src="./assets/images/logo/logo_white.svg"
            loading="lazy"
            alt="marketplace" />
          <img
            class="scroll-img"
            routerLink="/"
            src="./assets/images/logo/logo_color.svg"
            alt="marketplace" />
        </div>
      </div>
      <!-- END! nav-left -->

      <div class="nav-right" [class.active]="showHideResponsiveNav">
        <div class="right-responsive-img">
          <img
            src="/assets/images/logo/color.png"
            alt="marketplace"
            width="180"
            height="37"
            loading="lazy" />
          <div class="cross-btn">
            <a (click)="onShowHideResponsiveNav()"
              ><i class="fa-solid fa-circle-xmark"></i
            ></a>
          </div>
        </div>
        <ul class="head-nav">
          <li class="active">
            <a
              (click)="onShowHideResponsiveNav()"
              routerLink="ads"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }">
              <img
                class="helpIcon"
                src="../../../../assets/images/svg/service-fill.svg"
                alt="" />
              {{ 'HEADER.ALL_ADS' | translate }}
            </a>
          </li>
          <hr class="hori-line-mobile" />
          <li>
            <a
              (click)="onShowHideResponsiveNav()"
              routerLink="/blogs"
              routerLinkActive="active">
              <img
                class="helpIcon"
                src="../../../../assets/images/svg/thumb-up-fill.svg"
                alt="" />
              {{ 'HEADER.GOOD_TO_KNOW' | translate }}
            </a>
          </li>
          <hr class="hori-line-mobile" />
          <li>
            <a
              (click)="onShowHideResponsiveNav()"
              routerLink="support"
              routerLinkActive="active">
              <img
                class="helpIcon"
                src="../../../../assets/images/svg/questionnaire-fill.svg"
                alt="" />
              {{ 'HEADER.SUPPORT' | translate }}
            </a>
          </li>
          <hr class="hori-line-mobile" />
          <li>
            <a
              class="user-logo"
              (click)="onShowHideResponsiveNav()"
              routerLink="/account/profile"
              routerLinkActive="active">
              <i class="fa-regular fa-user profile-mobile-logo"></i>
              <img
                class="helpIcon"
                src="../../../../assets/images/svg/account-circle-fill.svg"
                alt="" />
              <span class="profile-icon">{{
                'HEADER.PROFILE' | translate
              }}</span>
            </a>
          </li>
          <hr class="hori-line-mobile" />
          @if (isLoggedIn) {
            <li class="logbtn">
              <a
                class="user-logo"
                routerLinkActive="active"
                (click)="onLogout()">
                <i class="fa-regular fa-user profile-mobile-logo"></i>
                <img
                  class="helpIcon"
                  src="../../../../assets/images/svg/logout-btn.svg"
                  alt="" />
                <span class="profile-icon">{{
                  'HEADER.LOG_OUT' | translate
                }}</span>
              </a>
            </li>
          }
          @if (isLoggedIn) {
            <hr class="hori-line-mobile" />
          }
          <li>
            <a
              class="create-btn"
              (click)="onCreateAdClick()"
              routerLinkActive="active">
              <i class="fas fa-plus"></i>
              {{ 'HEADER.CREATE_AD' | translate }}
            </a>
          </li>
        </ul>
      </div>
      <div
        class="right-responsive-overlay"
        [class.active]="showHideResponsiveNav"
        (click)="onShowHideResponsiveNav()"></div>
      <!-- END! nav-right -->

      <div class="nav-bars">
        <a (click)="onShowHideResponsiveNav()">
          <i class="fa-solid fa-bars"></i>
        </a>
      </div>
    </div>
    <!-- END! nav -->
  </div>
</header>
<!-- END! header -->

<header
  class="fixed-header"
  [ngClass]="{ active: changeColor, 'active-two': headerFixed === true }">
  <div class="container-header">
    <div class="nav">
      <div class="nav-left">
        <div class="left-img">
          <img
            class="pre-scroll-img"
            routerLink="/"
            src="./assets/images/logo/logo_white.svg"
            loading="lazy"
            alt="marketplace" />
          <img
            class="scroll-img"
            routerLink="/"
            src="./assets/images/logo/logo_color.svg"
            alt="marketplace" />
        </div>
      </div>
      <!-- END! nav-left -->

      <div class="nav-right" [class.active]="showHideResponsiveNav">
        <div class="right-responsive-img">
          <img src="./assets/images/logo/color-png.png" alt="marketplace" />
          <div class="cross-btn">
            <a (click)="onShowHideResponsiveNav()"
              ><i class="fa-solid fa-circle-xmark"></i
            ></a>
          </div>
        </div>
        <ul class="head-nav">
          <li class="active">
            <a
              (click)="onShowHideResponsiveNav()"
              routerLink="products"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }">
              {{ 'HEADER.ALL_ADS' | translate }}
            </a>
          </li>
          <li>
            <a
              (click)="onShowHideResponsiveNav()"
              routerLink="/blogs"
              routerLinkActive="active">
              {{ 'HEADER.GOOD_TO_KNOW' | translate }}
            </a>
          </li>
          <li>
            <a
              (click)="onShowHideResponsiveNav()"
              routerLink="support"
              routerLinkActive="active">
              {{ 'HEADER.SUPPORT' | translate }}
            </a>
          </li>
          <li>
            <a
              class="user-logo"
              (click)="onShowHideResponsiveNav()"
              routerLink="/account/profile"
              routerLinkActive="active">
              <i class="fa-regular fa-user"></i>
            </a>
          </li>
          <li>
            <a
              class="create-btn"
              (click)="onCreateAdClick()"
              routerLinkActive="active">
              <i class="fas fa-plus"></i>
              {{ 'HEADER.CREATE_AD' | translate }}
            </a>
          </li>
        </ul>
      </div>
      <div
        class="right-responsive-overlay"
        [class.active]="showHideResponsiveNav"
        (click)="onShowHideResponsiveNav()"></div>
      <!-- END! nav-right -->

      <div class="nav-bars">
        <a (click)="onShowHideResponsiveNav()">
          <i class="fa-solid fa-bars"></i>
        </a>
      </div>
    </div>
    <!-- END! nav -->
  </div>
</header>
<!-- END! header -->

@if (isCookieBannerVisible) {
  <div class="dialog footer-marquee-details">
    <div mat-dialog-content>
      <p>
        {{ 'COOKIE_BANNER.MESSAGE' | translate }}
        <span mat-dialog-close routerLink="/privacy-policy">{{
          'COOKIE_BANNER.LEARN_MORE' | translate
        }}</span>
      </p>
    </div>
    <div class="dialog-btn" mat-dialog-actions>
      <button class="dialog-button" (click)="isHideCookie()">
        {{ 'COOKIE_BANNER.GOT_IT' | translate }}
      </button>
    </div>
  </div>
}
