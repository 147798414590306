import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Advert } from 'interfaces/common';
import { AdvertService, UserService, HeaderService } from 'services/common';
import { UtilsService, UiService } from 'services/core';
import { OpenDalogComponent } from './open-dalog/open-dalog.component';
import { DATABASE_KEY } from '@/core/utils/global-variable';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  /* HEADER FIXED */
  headerFixed = false;
  isCookieBannerVisible = true;
  /**
   * Show and hide responsive nav.
   * Get subject data.
   */
  product: Advert | null = null;
  showHideResponsiveNav = false;
  changeColor = false;
  isLoggedIn = false;

  // Subscriptions
  private subAllProductByUser: Subscription = Subscription.EMPTY;
  private subGetProductDetail: Subscription = Subscription.EMPTY;
  private subGetProductByID: Subscription = Subscription.EMPTY;

  constructor(
    private _headerService: HeaderService,
    public dialog: MatDialog,
    public router: Router,
    public utilsService: UtilsService,
    public uiService: UiService,
    private advertService: AdvertService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  // TODO: duplicate identifier (this.isLoggedin)
  get isUserLogin(): boolean {
    return !!localStorage.getItem(DATABASE_KEY.encryptUserLogin);
  }

  ngOnInit(): void {
    const MILLISECONDS_IN_MONTH = 30 * 24 * 60 * 60 * 1000;
    if (this.isBrowser) {
      const lastDialogShown = localStorage.getItem('lastDialogShown');
      this.isLoggedIn = this.userService.getUserStatus();
      this.isCookieBannerVisible =
        !lastDialogShown ||
        Date.now() - parseInt(lastDialogShown, 10) > MILLISECONDS_IN_MONTH;
    }
    this._headerService.headerColorChange.subscribe(res => {
      this.changeColor = res;
    });
    // this.getSingleProduct();
    // this.openComponentDialog()
  }

  private getSingleProduct() {
    this.subAllProductByUser = this.advertService.getAdvertByUser().subscribe({
      next: res => {
        if (res.success) {
          this.product = res.data;
          this.routeRedirects();
        }
      },
      error: err => console.log(err),
    });
  }

  /**
   * RESPONSIVE NAV
   * HEADER FIXED
   */
  onShowHideResponsiveNav() {
    this.showHideResponsiveNav = !this.showHideResponsiveNav;
  }

  onCreateAdClick() {
    if (!this.isBrowser) return;
    this.onShowHideResponsiveNav();

    if (this.isUserLogin) this.getSingleProduct();
    else this.routeRedirects();
  }

  routeRedirects() {
    const productId = Number(this.product?.shortId) || 0;
    if (!this.isUserLogin) this.router.navigate(['/login']);

    if (!productId) this.router.navigate(['/create-new']);
    else
      this.router.navigate(['/create-new'], { queryParams: { id: productId } });
  }

  @HostListener('window:scroll')
  scrollBody() {
    this.headerFixed = window.scrollY > 50;
  }

  /**
   * OPEN COMPONENT DIALOG
   */

  public openComponentDialog() {
    this.dialog.open(OpenDalogComponent, {
      // panelClass: ['theme-dialog', 'full-screen-modal'],
      disableClose: true,
      height: 'auto',
      width: '350px',
      position: { left: '10px', bottom: '10px' },
    });
  }

  isHideCookie() {
    this.isCookieBannerVisible = false;
    if (this.isBrowser) {
      localStorage.setItem('lastDialogShown', Date.now().toString());
    }
  }

  isShowCookie() {
    const MILLISECONDS_IN_MONTH = 30 * 24 * 60 * 60 * 1000;
    if (this.isBrowser) {
      const lastDialogShown = localStorage.getItem('lastDialogShown');
      this.isCookieBannerVisible =
        !lastDialogShown ||
        Date.now() - parseInt(lastDialogShown, 10) > MILLISECONDS_IN_MONTH;
    }
  }

  onLogout() {
    this.userService.userLogOut();
    this.onShowHideResponsiveNav();
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.subAllProductByUser.unsubscribe();
    this.subGetProductByID.unsubscribe();
    this.subGetProductDetail.unsubscribe();
  }
}
