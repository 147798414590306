import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { UiService, StorageService, ReloadService } from 'services/core';
import { User, UserAuthResponse } from '../../interfaces/common/user.interface';
import { DATABASE_KEY } from '../../core/utils/global-variable';
import { Resp } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/user/';

@Injectable({ providedIn: 'root' })
export class UserService implements OnDestroy {
  private token?: string;
  private isUser = false;
  private userId!: string;
  private userStatusListener = new Subject<boolean>();
  // Hold The Count Time..
  private tokenTimer: any;
  private loginResponseSubscription: Subscription = Subscription.EMPTY;
  private forgotPasswordResponseSubscription: Subscription = Subscription.EMPTY;
  private resetPasswordResponseSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private uiService: UiService,
    private storageService: StorageService,
    private spinner: NgxSpinnerService,
    private reloadService: ReloadService
  ) {}

  /**
   * MAIN METHODS
   * userSignupAndLogin()
   * userLogin()
   */

  userSignupAndLogin(data: User, navigateFrom?: string) {
    this.httpClient
      .post<UserAuthResponse>(API_URL + 'signup-and-login', data)
      .subscribe(
        async res => {
          if (res.success) {
            this.token = res.token;
            // When Role & Permissions
            if (res.data) {
              this.userId = res.data.id;
              this.uiService.success(res.message);
            }
            // When Token
            if (res.token) {
              this.isUser = true;
              this.userStatusListener.next(true);
              // For Token Expired Time..
              const expiredInDuration = res.tokenExpiredIn;
              this.setSessionTimer(Number(expiredInDuration));
              // Save Login Time & Expiration Time & Token to Local Storage..
              const now = new Date();
              const expirationDate = new Date(
                now.getTime() + Number(expiredInDuration) * 1000
              );
              // Store to Local
              this.saveUserData(res.token, expirationDate, this.userId);

              this.spinner.hide();
              // Snack bar..
              this.uiService.success(res.message);
              // Navigate..
              if (navigateFrom) {
                this.router.navigate([navigateFrom]);
              } else {
                this.router.navigate([environment.userBaseUrl]);
              }
            }
          } else {
            if (res.message) this.uiService.wrong(res.message);
            this.spinner.hide();
            this.userStatusListener.next(false);
          }
        },
        error => {
          console.log(error);
          this.spinner.hide();
          this.userStatusListener.next(false);
          // console.log(error);
        }
      );
  }

  forgotPassword(data: { email: string }): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.forgotPasswordResponseSubscription = this.httpClient
        .post<UserAuthResponse>(API_URL + 'forgot-password', data)
        .subscribe(
          async res => {
            if (res.success) {
              this.spinner.hide();
              // Snack bar..
              this.uiService.success(res.message);

              observer.next(true); // Return true when login is successful
              observer.complete();
            } else {
              if (res.message) this.uiService.wrong(res.message);
              this.spinner.hide();
              observer.next(false); // Return false when login is unsuccessful
              observer.complete();
            }
          },
          error => {
            this.uiService.wrong(error?.error?.message);
            this.spinner.hide();
            observer.next(false); // Return false on error
            observer.complete();
          }
        );
    });
  }

  resetPassword(
    data: { password: string },
    token: string
  ): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      });
      this.resetPasswordResponseSubscription = this.httpClient
        .post<UserAuthResponse>(API_URL + 'set-new-password', data, {
          headers,
        })
        .subscribe(
          async res => {
            if (res.success) {
              this.spinner.hide();
              // Snack bar..
              this.uiService.success(res.message);

              observer.next(true); // Return true when login is successful
              observer.complete();
            } else {
              if (res.message) this.uiService.wrong(res.message);
              this.spinner.hide();
              observer.next(false); // Return false when login is unsuccessful
              observer.complete();
            }
          },
          error => {
            this.uiService.wrong(error?.error?.message);
            this.spinner.hide();
            observer.next(false); // Return false on error
            observer.complete();
          }
        );
    });
  }

  userLogin(
    data: { username: string; password: string },
    navigateFrom?: string
  ): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.loginResponseSubscription = this.httpClient
        .post<UserAuthResponse>(API_URL + 'login', data)
        .subscribe(
          async res => {
            if (res.success) {
              this.token = res.token;
              // When Role & Permissions
              if (res.data) {
                this.userId = res.data.id;
              }
              // When Token
              if (res.token) {
                this.isUser = true;
                this.userStatusListener.next(true);
                // For Token Expired Time..
                const expiredInDuration = res.tokenExpiredIn;
                this.setSessionTimer(Number(expiredInDuration));
                // Save Login Time & Expiration Time & Token to Local Storage..
                const now = new Date();
                const expirationDate = new Date(
                  now.getTime() + Number(expiredInDuration) * 1000
                );
                // Store to Local
                this.saveUserData(res.token, expirationDate, this.userId);

                this.spinner.hide();
                // Snack bar..
                this.uiService.success(res.message);
                // Navigate..
                if (navigateFrom) {
                  this.router.navigate([navigateFrom]).finally(() => {
                    window.location.reload();
                  });
                } else {
                  this.router
                    .navigate([environment.userBaseUrl])
                    .finally(() => {
                      window.location.reload();
                    });
                }
                observer.next(true); // Return true when login is successful
                observer.complete();
              }
            } else {
              if (res.message) this.uiService.wrong(res.message);
              this.spinner.hide();
              this.userStatusListener.next(false);
              observer.next(false); // Return false when login is unsuccessful
              observer.complete();
            }
          },
          error => {
            this.uiService.wrong(error?.error?.message);
            this.spinner.hide();
            this.userStatusListener.next(false);
            observer.next(false); // Return false on error
            observer.complete();
          }
        );
    });
  }

  /**
   * USER AFTER LOGGED IN METHODS
   * autoUserLoggedIn()
   * userLogOut()
   */
  autoUserLoggedIn() {
    const authInformation = this.getUserData();
    if (!authInformation) {
      this.storageService.removeFromLocal(DATABASE_KEY.encryptUserLogin);
      return;
    }
    const now = new Date();
    const expDate = new Date(authInformation.expiredDate);
    const expiresIn = expDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.userStatusListener.next(true);
      this.isUser = true;
      this.userId = authInformation.userId;
      this.setSessionTimer(expiresIn / 1000);
    }
  }

  userLogOut() {
    this.token = undefined;
    this.isUser = false;

    this.userStatusListener.next(false);
    this.reloadService.needRefreshCart$(false);
    // Clear Token from Storage..
    this.clearUserData();
    // Clear The Token Time..
    clearTimeout(this.tokenTimer);
    // Navigate..
    this.router.navigate([environment.userLoginUrl]);
  }

  /**
   * GET LOGGED IN BASE DATA
   * getUserStatus()
   * getUserToken()
   * getUserId()
   * getUserStatusListener()
   */

  getUserStatus() {
    return this.isUser;
  }

  getUserToken() {
    return this.token;
  }

  getUserId() {
    return this.userId;
  }

  getUserStatusListener() {
    return this.userStatusListener.asObservable();
  }

  /**
   * Save & GET User Info Encrypt to Local
   * saveUserData()
   * clearUserData()
   * getUserData()
   * setSessionTimer()
   */
  protected saveUserData(token: string, expiredDate: Date, userId: string) {
    const data = { token, expiredDate, userId };
    this.storageService.setInLocal(DATABASE_KEY.encryptUserLogin, data);
  }

  protected clearUserData() {
    this.storageService.removeFromLocal(DATABASE_KEY.encryptUserLogin);
  }

  protected getUserData() {
    try {
      return this.storageService.getFromLocal(DATABASE_KEY.encryptUserLogin);
    } catch (error) {
      console.warn(error);
    }
  }

  private setSessionTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.userLogOut();
    }, duration * 1000); // 1s = 1000ms
  }

  updateUsersById(_id: string, data: User) {
    return this.httpClient.put<Resp>(API_URL + 'update-data-user/', data);
  }

  ngOnDestroy(): void {
    this.loginResponseSubscription.unsubscribe();
    this.forgotPasswordResponseSubscription.unsubscribe();
    this.resetPasswordResponseSubscription.unsubscribe();
  }
}
